@import "bootstrap/bootstrap";

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

body, label, .checkbox label {
	font-weight: 300;
}

.content_container {
	padding: 0 2em;
}

.title_container {
    overflow: hidden;
}

.title_container.decks {
	border-bottom: 1px solid #ccc;
	margin:0 0 1em 0;
}

.title_container.deck h2 {
	margin:0;
}

.title_container h1 {
    display: inline-block;
    float: left;
	margin: 0 0 1em;
}

.title_container.decks h1 {
	margin: 0 0 .2em 0;
}

.title_container a {
    display: inline-block;
    float: right;
}

.product_container {
  overflow: hidden;
}

.product_container h1 {
  display: inline-block;
  float: left;
  margin: 0 0 1em;
}

.product_container a {
  display: inline-block;
  float: right;
}

.table_wrap {
	/*border-bottom: 1px solid #ccc;*/
	margin:0 0 1em 0;
}

.guid_fields input.form-control {
	display:inline-block;
}

input.form-control.guid_parent {
	width:20%;
}

input.form-control.guid_date {
	width:25%;
}
input.form-control.guid_client,
input.form-control.guid_title
{
	width:12%;
}
input.form-control.guid_type
{
	width:15%;
}

.group-container .dz-preview
{
  min-height: 50px;
  height: 50px;
  max-height: 50px;
  margin: 28 0 0 0;
}

.group-container .dropzone .dz-preview .dz-image
{
  height: 80px;
  width: 80px;
}

.group-container .dropzone .dz-preview .dz-details
{
  margin-top: 8px;
  padding: 4px;
}

.group-container .dropzone .dz-preview .dz-details
{
  font-size: 8px;
}

.group-container .dropzone .dz-preview .dz-details .dz-size
{
  font-size: 8px;
}
